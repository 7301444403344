<template>
  <section id="referencias">
    <ReferenciasView />
    <Footer />
  </section>
</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
  name: "Referencias",
  components: {
    ReferenciasView: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "ReferenciasView" */ "@/components/referencias/ReferenciasPrincipal.vue"
      )
    ),
    Footer: defineAsyncComponent(() =>
      import(/* webpackChunkName: "Footer-politica" */ "@/components/footer/Footer.vue")
    ),
  },
};
</script>